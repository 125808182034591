import fetch from '@/utils/fetch'

// 核销人员
export function writeOffListApi(query) {
  return fetch({
    url: 'admin/wxapp/write_off/list',
    method: 'get',
    params: query
  })
}

export function writeOffEdit(query) {
  return fetch({
    url: 'admin/wxapp/write_off/edit',
    method: 'post',
    params: query
  })
}

export function writeOffDel(query) {
  return fetch({
    url: 'admin/wxapp/write_off/del',
    method: 'post',
    params: query
  })
}

// 活动list
export function activityListApi(query) {
  return fetch({
    url: 'admin/wxapp/act/list',
    method: 'get',
    params: query
  })
}

// 编辑活动
export function activityEditApi(query) {
  return fetch({
    url: 'admin/wxapp/act/edit',
    method: 'post',
    params: query
  })
}
// 活动开启关闭
export function activityOpenApi(query) {
  return fetch({
    url: 'admin/wxapp/act/is_show',
    method: 'post',
    params: query
  })
}
// 活动详情
export function activityDetailApi(query) {
  return fetch({
    url: `admin/wxapp/act/detail/${query.id}`,
    method: 'get'
  })
}
// 活动删除
export function activityDelApi(query) {
  return fetch({
    url: 'admin/wxapp/act/del',
    method: 'post',
    params: query
  })
}

// 品牌列表
export function brandListApi(query) {
  return fetch({
    // url: '/goods/attributes',
    url: 'admin/wxapp/brand',
    method: 'get',
    params: query
  })
}

// 会员等级
export function memberListApi(query) {
  return fetch({
    url: '/membercard/grades',
    method: 'get',
    params: query
  })
}
// 报名记录列表
export function activityRecordListApi(query) {
  return fetch({
    url: 'admin/wxapp/register/list',
    method: 'get',
    params: query
  })
}
// 取消报名
export function activityRecordCancelApi(query) {
  return fetch({
    url: 'admin/wxapp/register/cancel',
    method: 'post',
    params: query
  })
}
// 报名记录详情
export function activityRecordDetailApi(query) {
  return fetch({
    url: `admin/wxapp/register/detail/${query.id}`,
    method: 'get'
  })
}

// 报名记录导出
export function activityRecordExportApi(query) {
  return fetch({
    url: 'admin/wxapp/register/export',
    method: 'get',
    params: query
  })
}

// 勋章
export function medalAllApi(query) {
  return fetch({
    url: 'admin/wxapp/medal/all',
    method: 'get',
    params: query
  })
}

// 更新banner
export function updateBanner(query) {
  return fetch({
    url: 'admin/wxapp/banner/update',
    method: 'post',
    params: query
  })
}

// 获取banner
export function bannerList(query) {
  return fetch({
    url: 'admin/wxapp/banner/list',
    method: 'post',
    params: query
  })
}
